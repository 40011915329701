import React, { Component } from "react";
import { InlineWidget } from "react-calendly";

class Calendly extends Component {
  componentDidMount() {
    // whatever stuff you need here
  }
  componentWillUnmount() {
    // whatever cleanup stuff you need here
  }
  render() {
    let url = this.props.url || "https://calendly.com/craig-185/new-client-meeting?primary_color=ff7f00";
    return (
      <div>
        <InlineWidget
          url={url}
          styles={{
            minWidth: "320px",
            height: "650px"
          }}
        />
      </div>
    );
  }
}

export const ContactPage = () => (
  <div>
    <Calendly />
  </div>
);

// // Add an calendlys embed site code here - The script is included in page.js
// export const Calendly = () => (
//   <div className="CalendlyDiv">
//     {/*// className="calendly-inline-widget"*/}
//     {/*// data-url="https://calendly.com/craig-185/30min?primary_color=ff9200"*/}
//     {/*// style={{ minWidth: "320px", height: "630px" }}*/}
//   </div>
// );
